<template>
  <v-app :style="fontStyle">
    <v-app-bar app flat height="56" color="accent">
      <!-- Show the menu button to toggle navigation drawer -->
      <v-app-bar-nav-icon class="d-lg-none" @click="shouldShowDrawer = true" />

      <v-toolbar-title v-if="appBarTitle" class="font-weight-bold">
        {{ appBarTitle }}
      </v-toolbar-title>

      <!-- Add invisible space in center -->
      <v-spacer />

      <!-- Show current token balance -->
      <v-chip :title="`You have ${availableCredits} tokens in credits`" color="primary" class="mr-4" small>
        <v-icon small left>
          token
        </v-icon>

        {{ availableCredits }}
      </v-chip>

      <!-- Show contact form option -->
      <v-icon class="mr-4" color="primary" @click="triggerContactForm">
        contact_support
      </v-icon>

      <!-- Show profile and notifications icons at the right -->
      <!-- <v-icon class="mr-4">notifications</v-icon> -->

      <v-menu>
        <!-- show the user icon -->
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-md-6" color="primary" v-bind="attrs" v-on="on"> person </v-icon>
        </template>

        <!-- show the list items for this user -->
        <v-list width="170px">
          <v-list-item v-if="profileValidity">
            <v-list-item-title class="cursor-pointer" :title="profileValidity.absolute">
              Expires {{ profileValidity.relative }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'Profile' }">
            <v-list-item-title>
              Profile
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="shouldShowInfoDialog = true">
            <v-list-item-title>
              Credit Guide
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleLogout">
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Progress loader -->
      <v-progress-linear
        :active="isLoaderVisible"
        :indeterminate="true"
        color="primary"
        absolute
        bottom
      />
    </v-app-bar>

    <v-navigation-drawer app color="navigation" v-model="shouldShowDrawer" class="sidebar">
      <!-- Show the app's logo -->
      <router-link :to="{ name: 'Dashboard' }" class="d-block px-2 mt-4">
        <v-img :src="profileLogo ? profileLogo : host.images.navigation" width="200" height="80" class="mx-auto" contain />
      </router-link>

      <!-- If we're showing a custom logo, show a text -->
      <div v-if="shouldShowPoweredByText" class="primaryText--text text-center text-subtitle-2 mt-2">
        Powered by <span :class="poweredByTextColor">Klug</span>
      </div>

      <!-- Show menu links -->
      <v-list class="mt-6 mt-lg-8 flex-grow-1" dense dark nav>
        <template v-for="item in menuItems">
          <v-subheader
            v-if="item.subheader"
            :key="item.subheader"
          >
            {{ item.subheader }}
          </v-subheader>

          <v-list-item
            v-else
            :key="item.to.name"
            :to="item.to"
          >
            <v-list-item-icon class="mr-4">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>

            <span
              v-if="item.beta"
              class="primary white--text navigation-label"
            >BETA</span>
          </v-list-item>
        </template>

        <template v-if="canUseAPI">
          <v-subheader>
            Others
          </v-subheader>

          <v-list-item @click="handleRedirect('/docs')">
            <v-list-item-icon class="mr-4">
              <v-icon> api </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> API Docs </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <!-- Show footer links -->
      <v-list
        v-if="shouldShowMenuFooter"
        dense
        dark
        nav
      >
        <v-list-item :to="{ name: 'FAQ' }">
          <v-list-item-content>
            <v-list-item-title> FAQs </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://klugklug.com/privacy-policy/">
          <v-list-item-content>
            <v-list-item-title>Privacy Policy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://klugklug.com/terms-and-conditions/">
          <v-list-item-content>
            <v-list-item-title>Terms &amp; Conditions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- The main view container -->
    <v-main class="accent">
      <div class="pa-3 px-md-9 py-md-6">
        <router-view :key="$route.path" />
      </div>

      <!-- Adding components here -->
      <toast-messages />
      <progress-bars />
      <notifications />

      <!-- The edit user form dialog -->
      <edit-profile-form v-if="profileData" v-model="shouldShowEditProfileDialog" @close="shouldShowEditProfileDialog = false" />

      <!-- The credit guide info dialog -->
      <v-dialog v-model="shouldShowInfoDialog" max-width="400">
        <v-card>
          <v-card-title class="primary white--text">
            <v-icon dark class="mr-3">
              support
            </v-icon>

            Credit Guide
          </v-card-title>

          <v-card-text class="pt-4">
            <p>1 token in Influencer Discovery unlocks 25 profiles</p>
            <v-divider class="mb-3" />
            <p>1 token in Influencer Insight unlocks 1 profile</p>
            <v-divider class="mb-3" />
            <p>1 token in Audience Overlap generates 1 report</p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn text color="primary" @click="shouldShowInfoDialog = false"> Okay </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
// Import helper functions
import dayjs from "@/helpers/dayjs.js"
import { getClientID } from "@/helpers/clientHelper"
import messageEvents from "@/helpers/messageEvents"
import downloadFile from "@/helpers/downloadFile"

// Import children components
const Notifications = () => import(/* webpackChunkName: "notifications" */ "@/components/common/Notifications.vue")
const ToastMessages = () => import(/* webpackChunkName: "toast-messages" */ "@/components/common/ToastMessages.vue")
const ProgressBars = () => import(/* webpackChunkName: "progress-bars" */ "@/components/common/ProgressBars.vue")
const EditProfileForm = () => import(/* webpackChunkName: "edit-profile" */ "@/components/profile/Edit.vue")

// Define the subscriptionId key for downloads
const downloadsSubscriptionId = Symbol("Template/Dashboard/Downloads")
const notificationsSubscriptionId = Symbol("Template/Dashboard/Notifications")

// Export the SFC
export default {
  // Name of the component
  name: "DashboardTemplate",

  // Register children components
  components: {
    ProgressBars,
    ToastMessages,
    Notifications,
    EditProfileForm
  },

  // Define local data variables
  data: () => ({
    // whether to show or not the sidebar drawer
    shouldShowDrawer: null,

    // let the user edit some basic account information
    shouldShowEditProfileDialog: false,

    // Show the user information about how much credit costs for different services
    shouldShowInfoDialog: false,

    items: [{ title: "List Item" }]
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the current header/appBar title from the route's meta property
     *
     * @returns {String|Null}
     */
    appBarTitle() {
      return this.$route.meta.appBarTitle || null
    },

    /**
     * Whether or not to show the global progress loader in UI
     *
     * @returns {Boolean}
     */
    isLoaderVisible() {
      return this.$store.getters["loaders/isVisible"]
    },

    /**
     * Get the logged in user's data object
     *
     * @returns {Object}
     */
    profileData() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Get the currently authenticated user's logo URL
     *
     * @returns {String}
     */
    profileLogo() {
      return this.$store.getters["auth/profileLogo"]
    },

    /**
     * Whether or not to show the powered by text
     *
     * @returns {Boolean}
     */
    shouldShowPoweredByText() {
      // If the host says to not
      if (host.shouldShowPoweredByText === false) {
        return false
      }

      // Otherwise, think of it as true
      return this.profileLogo && this.profileData.email !== "sapto.handriyanto@popsww.com"
    },

    /**
     * Get the color for powered by text
     *
     * @return {String}
     */
    poweredByTextColor() {
      // If the color for navigation and primary are same
      if (host.theme?.color?.navigation === host.theme?.color?.primary) {
        return "primaryText--text"
      }

      // Otherwise
      return "primary--text"
    },

    /**
     * Get the currently authenticated user's credit balance value
     *
     * @returns {Number}
     */
    availableCredits() {
      return this.$store.getters["auth/availableCredits"]
    },

    /**
     * Get the currently authenticated user's account validity
     *
     * @returns {Object|Null}
     */
    profileValidity() {
      if (this.profileData && this.profileData.valid_to) {
        return {
          relative: dayjs().to(dayjs.utc(this.profileData.valid_to)),
          absolute: dayjs.utc(this.profileData.valid_to).format("LLL")
        }
      } else {
        return null
      }
    },

    /**
     * Whether or not the currently authenticated user is allowed to use API
     *
     * @returns {Boolean}
     */
    canUseAPI() {
      return this.$store.getters["auth/profile"]?.can_use_api || false
    },

    /**
     * Get the sidebar menu link items
     *
     * @returns {Array}
     */
    menuItems() {
      const items = [
        {
          icon: "admin_panel_settings",
          text: "Administrator",
          condition: this.$store.getters["auth/isAdmin"],
          to: {
            name: "Admin/Index"
          }
        },
        {
          icon: "group",
          text: "Team Manager",
          condition: this.$store.getters["auth/isWhitelisted"],
          to: {
            name: "Team/Index"
          }
        },
        {
          subheader: "Influencers"
        },
        {
          icon: "person_search",
          text: "Influencer Discovery",
          serviceRequired: this.constants.model.user.allowedServices.influencerDiscovery,
          to: {
            name: "InfluencerDiscovery"
          }
        },
        {
          icon: "insights",
          text: "Influencer Insight",
          serviceRequired: this.constants.model.user.allowedServices.influencerInsight,
          to: {
            name: "InfluencerInsight"
          }
        },
        {
          icon: "join_left",
          text: "Audience Overlap",
          serviceRequired: this.constants.model.user.allowedServices.audienceOverlap,
          to: {
            name: "AudienceOverlapHistory"
          }
        },
        // {
        //   icon: "trending_up",
        //   text: "Google Trends",
        //   serviceRequired: this.constants.model.user.allowedServices.googleTrends,
        //   to: {
        //     name: "GoogleTrendsHistory"
        //   }
        // },
        {
          icon: "face",
          text: "Profile Overview",
          serviceRequired: this.constants.model.user.allowedServices.profileOverview,
          to: {
            name: "ProfileOverviewHistory"
          }
        },
        {
          icon: "sentiment_very_satisfied",
          text: "Social Sentiments",
          beta: true,
          serviceRequired: this.constants.model.user.allowedServices.socialSentiments,
          to: {
            name: "SocialSentimentsHistory"
          }
        },
        {
          icon: "radar",
          text: "Competitor Check",
          serviceRequired: this.constants.model.user.allowedServices.competitorCheck,
          to: {
            name: "CompetitorCheckHistory"
          }
        },
        // {
        //   icon: "collections",
        //   text: "Content Discovery",
        //   serviceRequired: this.constants.model.user.allowedServices.actionContentDiscovery,
        //   to: {
        //     name: "ContentDiscoveryHistory"
        //   }
        // },
        {
          icon: "receipt_long",
          text: "Generated Reports",
          to: {
            name: "Reports"
          }
        },
        {
          icon: "compare",
          text: "Influencer Tie Breaker",
          serviceRequired: this.constants.model.user.allowedServices.influencerTieBreaker,
          to: {
            name: "InfluencerTieBreaker"
          }
        },
        {
          icon: "query_stats",
          text: "Paid Collaborations",
          serviceRequired: this.constants.model.user.allowedServices.paidCollaborations,
          to: {
            name: "PaidCollaborationsIndex"
          }
        },
        // {
        //   icon: "difference",
        //   text: "Paid Comparison",
        //   beta: true,
        //   serviceRequired: this.constants.model.user.allowedServices.paidComparison,
        //   to: {
        //     name: "PaidCollaborationsComparison"
        //   }
        // },
        {
          subheader: "Campaigns",
        },

        {
          icon: "group",
          text: "Influencer Groups",
          serviceRequired: this.constants.model.user.allowedServices.influencerGroups,
          to: {
            name: "InfluencerGroupsIndex"
          }
        },
        {
          icon: "track_changes",
          text: "Campaign Tracking",
          serviceRequired: this.constants.model.user.allowedServices.campaignTracking,
          to: {
            name: "CampaignTrackingIndex"
          }
        },
        {
          icon: "tag",
          text: "Mention Tracking",
          serviceRequired: this.constants.model.user.allowedServices.mentionTracking,
          to: {
            name: "MentionTrackingIndex"
          }
        },
        {
          icon: "question_answer",
          text: "Influencer Outreach",
          serviceRequired: this.constants.model.user.allowedServices.influencerOutreach,
          to: {
            name: "InfluencerOutreachIndex"
          }
        },

        {
          icon: "travel_explore",
          text: "Campaign Planning",
          serviceRequired: this.constants.model.user.allowedServices.campaignPlanning,
          to: {
            name: "CampaignPlanningSearch"
          }
        }
      ]

      // now show the menu items by filtering based on conditions
      return (
        items
          // if there's some conditional value (kind of like v-if)
          .filter((item) => (typeof item.condition === "boolean" ? item.condition : true))
          // if the required service is allowed for this user
          .filter((item) => (item.serviceRequired ? this.$store.getters["auth/isServiceAllowed"](item.serviceRequired) : true))
      )
    },

    /**
     * Whether or not to show the links at the footer of menu
     *
     * @returns {Boolean}
     */
    shouldShowMenuFooter() {
      return host.shouldShowMenuFooter === false ? false : true
    }
  },

  // Define local method functions
  methods: {
    /**
     * Emit a global event to log the user out
     *
     * @returns {void}
     */
    handleLogout() {
      window.dispatchEvent(
        new CustomEvent("triggerLogout", {
          detail: {
            module: "dashboard",
            origin: "templates/dashboard/handleLogout"
          }
        })
      )
    },

    /**
     * Trigger an event to show the contact form to the user
     *
     * @returns {void}
     */
    triggerContactForm() {
      window.dispatchEvent(new CustomEvent("showContactForm", { detail: null }))
    },

    /**
     * When called, redirect the user to that HREF path
     *
     * @param {String} path
     * @returns {void}
     */
    handleRedirect(path) {
      window.location.href = path
    },

    /**
     * Show or hide the download progress bars
     *
     * @param {Object} e
     * @returns {void}
     */
    handleDownloadProgress(e) {
      // Now check if there's a client ID present in this response
      const clientId = e.localData.clientId || null

      // If the client ID matches or if there's no clientId
      if (getClientID() === clientId || clientId === null) {
        // Check if the event data has a toast message for us
        if (e.localData.toast) {
          this.$store.dispatch("toasts/add", {
            text: e.localData.toast,
            timeout: 5000
          })
        }

        // If the download progress is now complete
        if (e.localData.shouldEnd) {
          // Remove it
          this.$store.dispatch("progress/remove", e.localData)
        }
        // Otherwise show the progress bar
        else {
          this.$store.dispatch("progress/add", e.localData)
        }
      }
    },

    /**
     * Fetch the file from API and download it using JavaScript to the user's disk
     *
     * @param {Object} e
     * @returns {void}
     */
    async handleReadyToDownload(e) {
      // Dispatch a window event for anyone to listen to it
      window.dispatchEvent(
        new CustomEvent("ReadyToDownload", {
          detail: {
            origin: "templates/dashboard/mounted/echo/ReadyToDownload",
            data: e.temporaryFile
          }
        })
      )

      // Now check if there's a client ID present in this response
      const clientId = e.temporaryFile?.misc?.clientId || null

      // If the client ID matches or if there's no clientId, then download it now
      if (getClientID() === clientId || clientId === null) {
        // Now trigger the download for this file
        try {
          const response = await axios({
            url: "/api/temporary-files/" + e.temporaryFile.id,
            method: "POST"
          })

          // Now force this response to be downloaded
          downloadFile(response.data)
        } catch (error) {
          logger({ type: "DownloadFailed", error })

          this.$store.dispatch("toasts/add", { text: "Download failed, please try again." })
        }
      }
    },

    /**
     * When called, handle the data in message object
     *
     * @param {Object} eventData
     * @returns {void}
     */
    handleMessageEvent(eventData) {      // Use the helper function
      messageEvents.fireListeners(eventData)
    },

    /**
     * When called, handle the data and download the file
     *
     * @param {Object} eventData
     * @returns {void}
     */
    handleDownloadEvent(eventData) {
      // If we have the data available
      if (eventData.localData && eventData.localData.download) {
        // Use the helper function
        downloadFile(eventData.localData.download)
      }
    },

    /**
     * When called, handle the data and show a notification
     *
     * @param {Object} eventData
     * @returns {void}
     */
    handleNotificationEvent(eventData) {
      // If we have the data available
      if (eventData.localData && eventData.localData.notification) {
        // Dispatch the notification
        this.$store.dispatch("notifications/add", {
          text: eventData.localData.notification.text,
          type: eventData.localData.notification.type,
          icon: eventData.localData.notification.icon,
          button: eventData.localData.notification.button ? {
            text: eventData.localData.notification.button.text,
            action: () => {
              this.$router.push({
                name: eventData.localData.notification.button.route,
                params: eventData.localData.notification.button.params || {}
              })
            }
          } : null,
          event: {
            module: eventData.module,
            type: eventData.type,
            key: eventData.key
          }
        })
      }
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Let us know when this is done
    if (window.profileData) {
      // Start listening to the global laravel echo connection
      // Only register the event listeners once!
      if (window.HAS_COMMON_PUSHER_BEEN_SET) {
        return null
      }

      // Make sure we're not listening to the same events multiple times
      window.HAS_COMMON_PUSHER_BEEN_SET = "YES"

      // Register laravel echo events
      window.echo
        // Connect to the logged in user's events
        .private(`users.${window.profileData.id}`)
        // Listen to all the download progress events
        .listen("DownloadProgress", this.handleDownloadProgress)
        // Listen to the download ready responses
        .listen("ReadyToDownload", this.handleReadyToDownload)
        // Listen to all the message events
        .listen("MessageEvent", this.handleMessageEvent)

      window.HAS_ALL_ECHO_EVENTS_BEEN_SET = "YES"

      // Once we have registered the user web-socket events, register LuckyOrange
      window.LOQ = window.LOQ || []
      window.LOQ.push([
        "ready",
        async (LO) => {
          // Identify a visitor
          await LO.$internal.ready("visitor")
          LO.visitor.identify(window.profileData.id, {
            name: window.profileData.name,
            email: window.profileData.email
          })
        }
      ])
    }

    // Register a subscriber for downloads
    messageEvents.register({
      id: downloadsSubscriptionId,
      module: "all",
      type: "all",
      key: "all",
      validator: (event) => event.type === "download" && event.localData.clientId === getClientID(),
      callback: this.handleDownloadEvent
    })

    // Register a subscriber for notifications
    messageEvents.register({
      id: notificationsSubscriptionId,
      module: "all",
      type: "all",
      key: "all",
      validator: (event) => event.type === "notification",
      callback: this.handleNotificationEvent
    })
  },

  /**
   * Before the component is about to be deleted from DOM
   *
   * @returns {void}
   */
  beforeDestroy() {
    // If we do have the authenticated user's profile data
    if (this.profileData) {
      // Disconnect from the pusher channel
      window.echo.leave(`users.${this.profileData.id}`)
    }

    // Also, deregister the messageEvents listeners
    messageEvents.deregister(downloadsSubscriptionId)
    messageEvents.deregister(notificationsSubscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.brand-logo
  object-fit contain
  height 30px
  margin-right 1em

.contain-width-24
  width 24px

.v-application--is-ltr .v-list--dense.v-list--nav .v-list-group--no-action.crm-links > .v-list-group__items > .v-list-item
  padding-left 48px
</style>

<style lang="stylus">
// Global styling for sidebar
.sidebar
  .v-navigation-drawer__content
    display flex
    flex-direction column

    .v-list
      &:last-child
        .v-list-item
          min-height 18px

          &__content
            padding 4px 0

  // To fix the sidebar issue
  ::-webkit-scrollbar
    width 4px

  /* Track */
  ::-webkit-scrollbar-track
    background #f4f6f8

  /* Handle */
  ::-webkit-scrollbar-thumb
    background var(--v-secondary)

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover
    background var(--v-primary)

.navigation-label
  position absolute
  top 50%
  right 0.5rem
  font-size 0.75em
  font-weight bold
  border 1px solid
  line-height 1
  padding 1px 2px
  transform translateY(-50%)
</style>
